.sessao-rodape {
  background-color: #ffff;
  color: #000;
  padding-top: 2rem;
  min-width: 100%;
  padding-bottom: 2rem;

  .titulos {
    color: #104501;
    font-weight: bold;
    font-size: 1rem;
  }

  .item-menu {
    cursor: pointer;
    padding: 0.2rem 0;
    font-weight: 600;
  }

  .endereco {
    font-size: 1.1rem;
  }

  .redes-sociais {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
}
