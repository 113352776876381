.secao-cadastro {
  background-image: url("../../../../assets/images/banner-secao-cadastro-mobile.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 0;
  margin: auto;
  min-height: 100vh;

  @media (min-width: 1000px) {
    display: flex;
    align-items: center;
    background-image: url("../../../../assets/images/banner-secao-cadastro.jpg");
  }

  .titulo {
    font-size: 60px;
    color: #d8ff57;

    @media (min-width: 600px) {
      font-size: 64px;
      padding-top: 2rem;
    }
  }

  .beneficios {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;

    .item-beneficio {
      margin: 1rem 0;
      width: 100%;
      border: 4px solid #ffb3d2;
      border-radius: 2rem;
      padding: 1rem;

      .texto {
        display: flex;
        align-items: center;
        font-size: 20px;
      }

      @media (min-width: 1000px) {
        width: 80%;
      }
    }
  }

  .botao-login {
    margin: 2rem 0;
    padding: 0.6rem;
    background-color: #d8ff57;
    color: #004300;
    font-size: 1.1rem;

    @media (min-width: 1000px) {
      font-size: 1.5rem;
      margin: 0;
    }
  }
}
