.artigo-blog {
  .descricao {
    font-size: 20px;
    text-align: justify;
  }

  li {
    font-size: 20px;
  }
}
