.sessao-rodape-lp {
  background-color: #104501;
  color: #fff;
  padding-top: 2rem;
  min-width: 100%;
  padding-bottom: 2rem;

  .titulos {
    color: #d8ff57;
    font-weight: bold;
    font-size: 1rem;
  }

  .item-menu {
    cursor: pointer;
    padding: 0.2rem 0;
    font-weight: 600;

    a {
      color: #fff;
    }
  }

  .endereco {
    font-size: 1.1rem;
  }

  .redes-sociais {
    color: #fff;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
}
