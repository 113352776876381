.sessao-site {
  .logo-blog {
    padding-top: 2rem;
  }

  .sessao {
    padding-top: 2.5rem;

    .descricao {
      text-align: justify;
    }

    .titulo {
      text-align: center;
      padding-bottom: 2.5rem;
    }
  }

  .intro {
    .cartao {
      max-width: 100%;
      border-radius: 10%;
      padding: 10px;
    }

    .left-item {
      display: flex;
      align-items: center;
    }

    .rigth-item {
      display: flex;
      justify-content: center;

      img {
        width: 480px;
        border-radius: 30%;

        @media (max-width: 500px) {
          width: 360px;
        }
      }
    }
  }

  .beneficios,
  .tutorial {
    & li {
      margin-bottom: 0.9rem;

      text-align: justify;
    }

    & :last-child {
      margin-bottom: 0;
    }

    .lista {
      font-size: 18px;
    }
  }

  .botao-simulacao {
    color: #fff;
    font-size: 20px;
    margin: 1rem;
  }

  .quita {
    color: #179b46;
    font-weight: bold;
  }
}
