.swiper-button-next,
.swiper-button-prev {
  background-color: #004300;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #ffd12f;
  font-size: 18px;
}

.card-post {
  cursor: pointer;
  border-radius: 1rem;
  margin-bottom: 4rem;
  min-height: 350px;
  height: 450px;
}
