.secao-opiniao-clientes {
  background-color: #d8ff57;
  padding: 2rem;

  .cards-comentarios {
    display: flex;
    justify-content: center;

    .card-comentario {
      width: 85%;
      display: flex;
      align-items: center;
      height: 80%;
      margin: 3rem 0;
      border-radius: 2rem;

      @media (min-width: 1000px) {
        padding: 1rem;
        height: 250px;
      }

      .texto {
        padding: 2rem;
        display: flex;
        align-items: center;
        font-size: 20px;
      }
    }
  }

  .titulo {
    font-size: 48px;
    color: #104501;
  }
}
