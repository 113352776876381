.lp-carregamento {
  background-color: #d0f74d;
  max-width: 100%;
  width: 100%;
  height: 100vh;

  .title {
    font-size: 2.3rem;
    color: #145401;
  }

  .loader {
    display: flex;
    border: 3px solid #145401;
    border-radius: 1rem;
    margin-top: 1rem;

    .item-loader {
      background-color: #145401;
      border-radius: 0.5rem;
      padding: 1rem;
      margin: 0.5rem;

      &:nth-child(1) {
        animation: loader 2s alternate both;
      }

      &:nth-child(2) {
        animation: loader 2s alternate both;
        animation-delay: 2s;
      }

      &:nth-child(3) {
        animation: loader 2s alternate both;
        animation-delay: 4s;
      }

      &:nth-child(4) {
        animation: loader 2s alternate both;
        animation-delay: 6s;
      }

      &:nth-child(5) {
        animation: loader 2s alternate both;
        animation-delay: 8s;
      }

      @keyframes loader {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
}
