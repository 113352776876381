.toolbar {
  position: fixed;
  color: white;
  background-color: #fcfcfc;
  box-shadow: 0px 2px 4px -1px #00000038, 0px 1px 15px 0px #00000024,
    0px 0px 1px 0px #0000001f;

  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.botao-simular {
  font-weight: normal;
  color: white;
}
.logo-quita {
  cursor: pointer;
  max-width: 100px;
}
