.secao-newsletter {
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1000px) {
    margin: 8rem 0;
  }

  .titulo-newsletter {
    padding: 0 0 4rem 0;
    font-size: 1.7rem;
    font-weight: bold;
    color: #104501;
    text-align: center;

    @media (min-width: 1000px) {
      font-size: 2rem;
    }
  }

  .input-label {
    font-size: 1.5rem;
    color: #104501;
  }

  .botao-assinatura {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    background-color: #ffb3d2;
    color: #104501;
  }

  .sucesso {
    font-size: 1.1rem;
    color: #72bb5d;
    font-weight: bold;
  }

  .erro {
    font-size: 1.1rem;
    color: #e84528;
    font-weight: bold;
  }
}
