.pagina-parceiros{
  width: 100%;
  max-width: 100%;
}
.logo {
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  padding: 1rem;
  background-color: #e1ede5;
}
.logo > img {
  width: 100%;
}
.logo .increase-gains {
  font-size: 2.5rem;
  color: #363636;
  text-transform: uppercase;
  font-weight: 900;
  margin: 1rem;
}
.logo-text {
  display: flex;
  flex-flow: column;
}
.become-partner {
  font-size: 1.3rem;
  width: fit-content;
  color: white;
  background-color: #179b46 !important;
  margin: 1rem;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 10px ;
}

.logo .increase-gains > strong {
  font-weight: 900;
  color: #179b46;
}

.numbers {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  background: white;
  padding: 2rem;
}
.numbers div {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.numbers img {
  width: 7rem;
  margin-top: 1rem;
}

.numbers .spotlight {
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #179b46;
}

.perks-list *::before {
  content: "▶";
  color: #179b46;
  font-size: 1.2rem;
  margin-right: 1rem;
}

.perks {
  padding: 2rem;
}
.perks-right {
  display: none;
}

.perks-list li {
  font-size: 1.2rem;
}

.perks strong {
  font-weight: 900;
  color: #179b46;
  font-size: 2rem;
  padding: 5px;
  border-radius: 15px;
  margin: 0px 5px 0px 0px;
}

.partners {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}
.partners .partners-title {
  font-weight: 900;
  font-size: 1.3rem;
}
.partners img {
  width: 80%;
  margin: -2rem;
}
.partners img:last-child {
  margin-bottom: 0px;
}
.partners img:first-child {
  margin-top: 0px;
}

.partners-img {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  align-items: center;
}

.partner-type {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 2rem;
}

.partner-type > .partner-description {
  margin: 0px 0px 2rem 0px;
}
.partner-title strong {
  color: #179b46;
  font-size: 2rem;
}
.partner-title {
  text-align: center;
}
.partner1-title,
.partner2-title {
  font-weight: 900;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3rem;
}
.partner-description {
  font-size: 1.2rem;
  text-align: center;
}

.partner-container {
  border: 1px solid #5bac77;
  border-radius: 8px;
  padding: 1rem;
  margin: 10px;
}

@media (min-width: 500px) {
  .logo {
    display: flex;
    flex-flow: wrap row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
  }
  .logo .increase-gains {
    font-size: 3.1rem;
  }
  .logo > img {
    width: 40%;
  }
  .logo-text {
    display: flex;
    width: 50%;
    flex-flow: column;
  }

  .numbers img {
    width: 7rem;
    margin-top: 1rem;
  }

  .numbers .spotlight {
    font-weight: 900;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #179b46;
  }
  .perks-left {
    width: 50%;
  }
  .perks-right {
    display: block;
    height: 400px;
  }
  .perks-right img {
    height: 100%;
  }

  .perks-list {
    margin: 2rem 0px;
  }

  .perks {
    display: flex;
    justify-content: space-evenly;
  }

  .partners {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }

  .partner-title {
    text-align: left;
  }
  .partners img {
    width: 30%;
  }
  .partners img:last-child,
  .partners img:first-child {
    margin: 0px;
  }

  .partners-img {
    justify-content: space-around;
    flex-flow: row;
  }

  .partner-type {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }


  .partner-type > .partner-description {
    width: 60%;
    margin: 1rem 0px;
  }
  .partner-type > div {
    display: flex;
    justify-content: space-evenly;
  }
  .partner-container {
    width: 45%;
    border: 1px solid #75d597;
    border-radius: 10PX;
  }
}
