.secao-landing-page {
  background-color: #fff;
  line-height: 1.5;
  padding: 0 2rem;
  width: 100%;
  max-width: 100%;
  margin: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 2rem 0;

  @media (max-width: 1000px) {
    padding: 0;
  }
}

.secao-1-lp {
  background-image: url("../../../assets/landing-pages/pix-parcelado/banner-lp-pix-parcelado.png");
  color: #fff;
  padding: 0;
  margin: auto;
  height: 100vh;

  @media (max-width: 1000px) {
    display: flex;
    align-items: flex-start;
    background-image: url("../../../assets/landing-pages/pix-parcelado/banner-lp-pix-parcelado-mobile.png");
  }

  .texto-secundario {
    font-size: 1.5rem;
    color: #104501;
    margin: 1rem 0;

    @media (min-width: 600px) {
      font-size: 2rem;
    }
  }

  .botao-parcelar {
    width: 100%;
    font-size: 1rem;
    color: #d8ff57;

    @media (min-width: 600px) {
      font-size: 2rem;
    }
  }

  .aviso-pagamento {
    font-size: 1.2rem;
    margin: 3rem 0;
    font-weight: bold;
  }
}

.secao-2-lp {
  background-color: #f0fce3;

  @media (max-width: 600px) {
    padding: 2rem 0;
  }

  .logo-atendimento {
    cursor: pointer;
    top: 175px;
    left: 20px;
    width: 100px;
    position: relative;
    padding: 0.5rem;

    @media (min-width: 1000px) {
      &:hover {
        scale: 1.1;
      }
    }

    .card-logo {
      border-radius: 1rem;
      background-color: #104501;
      display: flex;
      justify-content: center;
    }
  }

  .card-atendimento {
    border-radius: 1rem;
    background-color: #d8ff57;
    margin-bottom: 1rem;
    min-width: 70%;
    width: 70%;
    min-height: 130px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .texto {
    font-size: 2rem;
    text-align: right;
    font-weight: bold;
    padding: 2rem 4rem;
    align-self: center;
    line-height: 1.2;
    color: #104501;
  }

  .card-anuncio {
    background-image: url("../../../assets/landing-pages/pix-parcelado/vector.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #e3ffc5;
    min-width: 50%;
    width: 60%;
    padding: 5rem;
    border-radius: 2rem;
    margin: auto;

    @media (max-width: 1000px) {
      margin-bottom: 2rem;
      width: 90%;
      padding: 1rem;
    }

    @media (max-width: 600px) {
      width: 100%;
      padding: 1rem;
    }

    .titulo-anuncio {
      text-align: center;
      font-size: 3rem;
      color: #2e531b;

      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    .texto-anuncio {
      text-align: center;
      font-size: 1.5rem;

      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }

    .destaque {
      font-weight: bold;
    }
  }
}

.secao-3-lp {
  .titulo {
    font-size: 3rem;
    color: #104501;

    @media (max-width: 1000px) {
      margin-top: 1rem;
    }

    @media (max-width: 600px) {
      font-size: 2rem;
    }
  }

  .descricao {
    font-size: 1.5rem;

    @media (max-width: 1000px) {
      padding: 0 1rem;
    }
  }

  .botao-parcelar {
    background-color: #ffb3d2;
    color: #104501;
    margin: 2rem 0;
    font-size: 1.5rem;
    padding: 1rem;
  }
}

.secao-4-lp {
  .titulo {
    width: 60%;
    text-align: left;
    margin: 2rem 0;
    font-weight: bold;
    color: #ffb3d2;
    font-size: 3rem;

    @media (max-width: 1000px) {
      text-align: center;
      width: 100%;
    }
  }
  .texto {
    padding: 1rem;
    margin: 2rem 0;
    font-size: 1.5rem;

    @media (max-width: 600px) {
      margin: 0;
    }
  }

  .botao-parcelar-agora {
    font-size: 1.5rem;
    color: #104501;
    background-color: #ffc700;

    @media (max-width: 1000px) {
      margin: 0rem 0 3rem 0;
    }
  }
}

.secao-5-lp {
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-image: url("../../../assets/landing-pages/pix-parcelado/vector-sessao-5.svg");
  background-color: #f0fce3;

  @media (max-width: 1000px) {
    background-size: cover;
  }

  .container-secao-5 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-explicacao {
      display: flex;
      align-items: center;
      background-color: #e3ffc5;
      border-radius: 1rem;
      padding: 2.5rem;
      width: 60%;
      margin: 2rem 0;

      @media (max-width: 1000px) {
        width: 80%;
        padding: 1rem;
      }

      .container-titulo-explicacao {
        align-items: center;
        display: flex;
        justify-content: center;

        .texto {
          text-align: center;
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }
  }
}

.secao-6-lp {
  display: flex;
  justify-content: center;

  .container-secao-6 {
    background-color: #ffb3d2;
    width: 95%;
    border-radius: 2rem;

    @media (max-width: 1000px) {
      margin: 1rem 0;
    }
  }

  .vector-secao-6 {
    @media (max-width: 600px) {
      width: 30%;
      margin: 0 2rem;
    }
  }

  .titulo {
    font-size: 4rem;
    color: #fff;
    margin: 0 2.5rem;

    @media (max-width: 1000px) {
      text-align: start;
      font-size: 3rem;
      margin: 0;
    }

    @media (max-width: 600px) {
      text-align: start;
      font-size: 2.5rem;
      margin: 0;
    }
  }

  .titulo-vantagem {
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;

    @media (max-width: 600px) {
      margin: 1rem 0.5rem;
      font-size: 1.5rem;
    }
  }

  .descricao-vantagem {
    font-size: 1.5rem;

    @media (max-width: 1000px) {
      text-align: center;
      margin: 1rem 0.5rem;
      line-height: 1.2;
    }
  }

  .botao-parcele-seu-pix-agora {
    margin: 2rem 0.9rem;
    background-color: #d8ff57;
    color: #104501;
    font-size: 1.5rem;
  }
}
