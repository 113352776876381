.sessao-site {
  .logo-blog {
    padding-top: 2rem;
  }

  .sessao {
    padding-top: 2rem;
    display: flex;
    justify-content: center;

    .descricao {
      text-align: justify;
    }

    .titulo {
      padding-bottom: 2.5rem;
    }
  }

  .intro {
    .img-intro {
      display: flex;
      justify-content: center;

      img {
        width: 60%;
        border-radius: 10%;
        padding: 10px;

        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }

  .descricao-beneficios {
    font-size: 18px;
    margin-bottom: 0.5rem;

    &:first-child {
      margin-top: 1rem;
    }
  }

  .conclusao {
    margin-bottom: 2rem;
    .descricao {
      font-size: 18px;
      margin-bottom: 0.5rem;
    }
  }

  .simulacao {
    display: flex;
    justify-content: center;
  }

  .quita {
    color: #179b46;
    font-weight: bold;
  }
  .greenUnderline {
    text-decoration: underline;
    text-decoration-color: #179b46;
    text-decoration-thickness: 3px;
  }
}
