.sessao-blog {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 2rem 0;
  background-color: #fff;
}

.secao-posts {
  background-image: url("../../../assets/images/banner-blog.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffc700;
  padding: 2.5rem;
  font-size: 3rem;
  font-weight: 900;
}

.logo-blog-img {
  max-height: 400px;
}

.sessao-post {
  min-width: 100%;
  width: 100%;
  background-color: #fff;
  border-bottom: 2px solid #104501;
}
