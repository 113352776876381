.secao-site-perguntas {
  width: 100%;
  max-width: 100%;
  margin: auto;
  position: relative;
  padding: 2rem;
  background-color: #fdfdfd91;

  @media (max-width: 600px) {
    padding: 1rem;
  }
}

.secao-faq {
  display: flex;
  flex-direction: column;
  align-items: center;

  .titulo {
    margin: 2.3rem 0;
    font-size: 2rem;
    text-align: center;
  }

  .titulo-item-faq {
    background-color: #d8ff57;
    padding: 1rem;
  }

  .item-faq {
    .descricao {
      font-size: 1.2rem;
    }
  }

  .item-faq:last-child {
    margin-bottom: 3rem;
  }

  .botao-fale-conosco-amarelo {
    margin: 2rem 0;
    font-size: 1.3rem;
    background-color: #ffd12f;
    color: #104501;
  }

  .botao-fale-conosco-verde {
    font-size: 1.4rem;
    color: #d8ff57;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
}
